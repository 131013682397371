import { Controller } from "@hotwired/stimulus";
class src_default extends Controller {
  async show(event) {
    const element = event.currentTarget;
    let content = null;
    if (this.hasContentTarget) {
      content = this.contentTarget.innerHTML;
    } else {
      content = await this.fetch();
    }
    if (!content)
      return;
    const fragment = document.createRange().createContextualFragment(content);
    element.appendChild(fragment);
  }
  hide() {
    if (this.hasCardTarget) {
      this.cardTarget.remove();
    }
  }
  async fetch() {
    if (!this.remoteContent) {
      if (!this.hasUrlValue) {
        console.error("[stimulus-popover] You need to pass an url to fetch the popover content.");
        return;
      }
      const response = await fetch(this.urlValue);
      this.remoteContent = await response.text();
    }
    return this.remoteContent;
  }
}
src_default.targets = ["card", "content"];
src_default.values = {
  url: String
};
export { src_default as default };
